<template>
  <div class="overview-page admin-page">
    <div class="container">
      <AdminHeader />

      <h2 class="heading">Übersicht</h2>

      <v-list two-line class="list">
        <v-list-item-group>
          <v-list-item class="item" to="/admin/campaigns/">
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title class="itemTitle">
                  Gutschein-Kampagnen
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider class="mx-3"></v-divider>

          <v-list-item class="item" to="/admin/users/">
            <template v-slot:default>
              <v-list-item-content>
                <v-list-item-title class="itemTitle">
                  Benutzer
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>
<script>
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminOverview',
  components: {
    AdminHeader,
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.overview-page {
  .container {
    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }

    .list {
      border-radius: 8px;
      border: 1px solid rgba(#000000, 0.1);

      .item {
        .itemTitle {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
